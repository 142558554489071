<template>
    <div class="container-fluid overflow-x-auto p-2">
        <nl-loader v-if="preloading" :show="true" />
        <div v-else>
            <b-loading v-model="isLoading" :is-full-page="true" />

            <p>
                <button class="button is-white text-dark is-small" @click="backToOrders">
                    <i class="fas fa-backward mr-1" />
                    {{ $t('partenaire-commandes.back') }}
                </button>
            </p>

            <div v-if="!order">
                <b-notification type="is-warning is-light" :closable="false" role="alert">
                    {{ $t('partenaire-commandes.orderdetail.nofoundorder') }}
                </b-notification>
            </div>

            <div v-else class="d-flex flex-column flex-md-row justify-content-between">
                <section class="box box-responsive mr-md-5 position-relative">
                    <section class="">
                        <article class="mb-2">
                            <p class="mb-1 font-weight-bold mr-md-2">Page</p>
                            <p class="">
                                <span>{{ order.url }}</span>
                            </p>
                        </article>
                        <article class="mb-2">
                            <p class="mb-1 font-weight-bold mr-md-2">
                                {{ $t('links&anchors.wantedlinks') }}
                            </p>
                            <p :class="order.link_second_url ? 'mb-1' : ''">
                                <span>{{ $t('global.link') }} 1 :</span>
                                <span>{{ order.link_url }}</span>
                            </p>
                            <p v-if="order.link_second_url" class="">
                                <span>{{ $t('global.link') }} 2 :</span>
                                <span>{{ order.link_second_url }}</span>
                            </p>
                        </article>
                        <article class="mb-2">
                            <p class="mb-1 font-weight-bold mr-md-2">
                                {{ $t('links&anchors.wantedanchors') }}
                            </p>
                            <p :class="order.link_second_anchor ? 'mb-1' : ''">
                                <span>{{ $t('global.anchor') }} 1 :</span>
                                <span>{{ order.link_anchor }}</span>
                            </p>
                            <p v-if="order.link_second_anchor" class="">
                                <span>{{ $t('global.anchor') }} 2 :</span>
                                <span>{{ order.link_second_anchor }}</span>
                            </p>
                        </article>
                    </section>

                    <section class="box-large">
                        <article class="mb-2">
                            <p class="mb-1 font-weight-bold mr-md-2">
                                {{ $t('global.brief') }}
                            </p>
                            <p class="has-background-light p-3 rounded">
                                {{ order.brief }}
                            </p>
                        </article>
                        <article class="mb-4">
                            <div class="d-flex align-items-center mb-2 justify-content-between">
                                <p class="mb-1 font-weight-bold mr-md-2">
                                    {{ $t('global.content') }}
                                </p>
                                <button type="button" class="button is-secondary is-light" @click="copyContent">
                                    <i class="far fa-copy mr-2" />{{ $t('global.copy') }}
                                </button>
                            </div>

                            <p v-if="order.redaction !== 'nextlevel'">
                                {{ 200 + parseInt(order.option_mots) }}
                                {{ $t('partenaire-commandes.wordsyou') }}
                            </p>
                            <div v-else>
                                <p
                                    v-if="order.statut === 'a_valider' && !order.contenu_date_envoi"
                                    class="has-background-light p-3 rounded"
                                >
                                    <i class="fas fa-info-circle mr-2" />
                                    {{ $t('partenaire-commandes.contentredactionwaiting') }}
                                </p>
                                <p
                                    v-else-if="
                                        order.statut === 'en_cours' && !order.contenu_date_envoi && !order.contenu
                                    "
                                    class="has-background-light p-3 rounded"
                                >
                                    <i class="fas fa-info-circle mr-2" />
                                    {{ $t('partenaire-commandes.contentredaction') }}
                                </p>

                                <textarea
                                    v-else-if="order.contenu && order.contenu_date_envoi"
                                    class="form-control"
                                    disabled
                                    rows="15"
                                    v-text="order.contenu"
                                />
                            </div>
                        </article>
                        <article class="mb-2 earning-box">
                            <p class="mb-1 font-weight-bold">
                                <b-tooltip :label="$t('partenaire-commandes.gains')" dashed position="is-right">
                                    {{ $t('global.earnings') }}
                                </b-tooltip>
                            </p>
                            <p class="earning-amount">{{ order.earning.toFixed(2) }}€</p>
                            <button
                                v-if="
                                    order.statut === 'en_cours' &&
                                    (order.redaction === 'proprietaire' ||
                                        (order.redaction === 'nextlevel' && order.contenu_date_envoi))
                                "
                                type="submit"
                                class="button is-success position-absolute d-none d-md-block"
                                style="bottom: -3em; right: 0.5em"
                                @click="openConfirmModal('published')"
                            >
                                <i class="fas fa-clipboard-check mr-2" />
                                {{ $t('partenaire-commandes.published') }}
                            </button>
                        </article>
                    </section>

                    <section class="buttons justify-content-center my-4">
                        <button
                            v-if="order.statut === 'en_cours' && order.redaction !== 'nextlevel'"
                            type="button"
                            class="button is-info is-light whitespace-normal"
                            @click="openConfirmModal('redactionNL')"
                        >
                            <i class="fas fa-pencil-alt mr-2" />
                            {{ $t('partenaire-commandes.redactionNL') }}
                        </button>
                        <button
                            v-if="
                                order.statut === 'en_cours' &&
                                (order.redaction === 'proprietaire' ||
                                    (order.redaction === 'nextlevel' && order.contenu_date_envoi))
                            "
                            type="submit"
                            class="button is-success"
                            @click="openConfirmModal('published')"
                        >
                            <i class="fas fa-clipboard-check mr-2" />
                            {{ $t('partenaire-commandes.published') }}
                        </button>
                        <button
                            v-if="order.statut === 'a_valider'"
                            type="submit"
                            class="button is-success is-light"
                            @click="openConfirmModal('acceptorder')"
                        >
                            <i class="far fa-check-circle mr-1" />
                            {{ $t('partenaire-commandes.acceptorder') }}
                        </button>
                        <button
                            v-if="order.statut === 'a_valider'"
                            type="button"
                            class="d-inline-block mr-2 refus button is-danger is-light"
                            @click="openConfirmModal('refuseorder')"
                        >
                            <i class="far fa-times-circle mr-1" />
                            {{ $t('partenaire-commandes.Irefuseorder') }}
                        </button>
                        <button
                            v-if="order.statut === 'a_valider' && order.redaction !== 'nextlevel'"
                            type="button"
                            class="d-inline-block mr-2 refus button is-info is-light"
                            @click="openConfirmModal('acceptredactionorder')"
                        >
                            <i class="far fa-times-circle mr-1" />
                            {{ $t('partenaire-commandes.acceptredactionNL') }}
                        </button>
                    </section>
                </section>

                <section class="d-none d-md-block w-25">
                    <img src="/assets/img/zebre-pouce.svg" alt="zebre pouce levé" />
                </section>

                <PartnerOrdersDetailsConfirmModal
                    ref="confirmModal"
                    :orders="[order]"
                    @redactionOrder="redactionOrder"
                    @acceptRedactionOrder="acceptRedactionOrder"
                    @acceptOrder="acceptOrder"
                    @publishOrder="publishOrder"
                    @refuseOrder="refuseOrder"
                />
            </div>
        </div>

        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'

    export default {
        name: 'PartnerOrdersDetails',
        title: 'partenaire-commandes-detail.title',
        components: {
            Toaster
        },
        data: function () {
            return {
                order: null,
                orderId: null,

                preloading: false,
                isLoading: false
            }
        },
        created() {
            this.orderId = this.$route.query.id

            if (!this.orderId) {
                this.backToOrders()
                return
            }

            this.getData()
        },
        mounted: function () {
            // Décommenter pour avoir un fond jaune
            // document.getElementsByTagName('body')[0].style.backgroundColor = '#FFF5DA';

            document.querySelectorAll('.container-fluid').forEach(div => div.classList.add('p-4'))
        },
        methods: {
            getData: function () {
                this.preloading = true

                var order = this.axios
                    .get(`/partner-orders/${this.orderId}`)
                    .then(response => {
                        this.order = response.data.order

                        if (this.order.statut === 'annule' || this.order.statut === 'refuse') {
                            this.backToOrders()
                        }
                    })
                    .catch(error => {
                        console.error(error)

                        this.backToOrders()
                    })

                Promise.all([order]).then(() => {
                    this.preloading = false
                })
            },

            copyContent() {
                var $temp = $('<input>')
                $('body').append($temp)
                $temp.val(this.order.contenu).select()
                document.execCommand('copy')
                this.snackNotif(this.$t('global.copied'), 'is-success')
                $temp.remove()
            },

            refuseOrder(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/refuse`, {
                        ids: params.ids,
                        choice: params.choice,
                        motif_refus_autre: params.motif_refus_autre,
                        min_price_url: params.min_price_url,
                        min_price_site: params.min_price_site
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-order'), 'is-danger')
                            return
                        }

                        this.snackNotif(
                            `${this.$t('partenaire-commandes.valid-edit-order')}. ${this.$t(
                                'partenaire-commandes.back'
                            )}`
                        )

                        setTimeout(() => this.backToOrders(), 1000)
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            publishOrder(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/published`, {
                        ids: params.ids
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(
                                `${this.$t('partenaire-commandes.invalid-edit-order')}. ${this.$t(
                                    'partenaire-commandes.verify-date'
                                )}`,
                                'is-danger'
                            )
                            return
                        }

                        if (
                            response.data.result.some(
                                order => order.link_status_message || order.link_second_status_message
                            )
                        ) {
                            this.snackNotif(this.$t('partenaire-commandes.found-errors'), 'is-danger')
                        } else {
                            this.snackNotif(this.$t('partenaire-commandes.valid-edit-orders'))
                        }

                        this.order = response.data.result[0]
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            acceptOrder(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/accept`, {
                        ids: params.ids
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-order'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-order'))

                        this.order = response.data.order
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            acceptRedactionOrder(params) {
                this.isLoading = true
                this.axios
                    .patch('/partner-orders/accept-nl', {
                        ids: params.ids,
                        brief: params.brief
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-order'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-order'))

                        this.order = response.data.order
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            redactionOrder(params) {
                this.isLoading = true
                this.axios
                    .patch(`/partner-orders/redaction`, {
                        ids: params.ids,
                        brief: params.brief
                    })
                    .then(response => {
                        if (!response.data.result) {
                            this.snackNotif(this.$t('partenaire-commandes.invalid-edit-order'), 'is-danger')
                            return
                        }

                        this.snackNotif(this.$t('partenaire-commandes.valid-edit-order'))

                        this.order = response.data.order
                    })
                    .catch(error => {
                        console.error(error)
                        this.snackNotif(error.response.data.message, 'is-danger')
                    })
                    .finally(() => (this.isLoading = false))
            },

            openConfirmModal(type) {
                this.$refs.confirmModal.openModal(type)
            },

            backToOrders() {
                window.location.href = '/partner/orders'
            }
        }
    }
</script>

<style scoped>
    .box-responsive {
        width: 100%;
    }

    .box-responsive p {
        word-break: break-all;
    }

    .whitespace-normal {
        white-space: normal;
    }

    @media screen and (min-width: 768px) {
        .box-responsive {
            width: 75%;
        }

        .box-responsive article {
            width: 75%;
        }

        .box-responsive .box-large article {
            width: 100%;
        }

        .earning-box {
            position: absolute;
            top: 1.5em;
            right: 2em;
            display: inline;
            width: 150px !important;
            text-align: center;
            padding: 2em 0;
            border-radius: 50%;
            box-shadow: 2px 3px 8px rgb(0 0 0 / 10%);
            background-color: #ffdd57;
        }

        .earning-amount {
            font-size: 2em;
            font-weight: bold;
        }
    }
</style>
